var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "profile-page"
  }, [!_vm.isLoading ? [_c('statistics-info', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.activeStep == 0,
      expression: "activeStep == 0"
    }],
    attrs: {
      "data": _vm.getFullUserProfile
    },
    on: {
      "on-change-step": _vm.changeStep
    }
  }), _c('personal-info', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.activeStep == 1,
      expression: "activeStep == 1"
    }],
    attrs: {
      "data": _vm.profile
    },
    on: {
      "on-change-step": _vm.changeStep
    }
  })] : [_c('cover-loader', {
    attrs: {
      "is-cover": ""
    }
  })]], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }