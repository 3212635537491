<template>
  <div class="profile-page">
    <template v-if="!isLoading">
      <statistics-info v-show="activeStep == 0" :data="getFullUserProfile" @on-change-step="changeStep" />
      <personal-info v-show="activeStep == 1" :data="profile" @on-change-step="changeStep" />
    </template>
    <template v-else>
      <cover-loader is-cover />
    </template>
  </div>
</template>

<script>
import PersonalInfo from "../../components/profile/personal-info/Main.vue";
import statisticsInfo from "../../components/profile/statistics/Main.vue";
import CoverLoader from "../../components/shared/CoverLoader.vue";
import profileRepository from "../../repositories/profileRepository";
// import toasted from "@/app/shared/utilities/toasted";

export default {
  metaInfo: {
    title: "مشروع زادي | الملف الشخصي",
  },
  components: { PersonalInfo, statisticsInfo, CoverLoader },
  data() {
    return {
      profile: null,
      loyalty: null,
      isLoading: false,
      activeStep: 0,
    }
  },
  methods: {
    changeStep() {
      this.activeStep = this.activeStep == 0 ? 1 : 0;
    },
    async fetchUserProfile() {
      try {
        const { data } = await profileRepository.getUserProfile();
        this.profile = data;
        return data;
      } catch (error) {
        throw error;
      }
    },
    async fetchUserPoints(userId) {
      try {
        const { data } = await profileRepository.getUserPoints(userId);
        this.loyalty = data;
        return data;
      } catch (error) {
        throw error;
        // if (error.response.data.message === "Client not found") {
        //   toasted.failed("عليك القيام التفاعل في المنصة لتحصل على نقاط");              
        // }
      }
    },
  },
  computed: {
    getFullUserProfile() {
      return {
        profile: this.profile,
        loyalty: this.loyalty,
      }
    },
  },
  async mounted() {
    this.isLoading = true;
    try {
      const profile = await this.fetchUserProfile();
      if (profile?.id) {
        await this.fetchUserPoints(profile.id);
      }
    } finally {
      this.isLoading = false;
    }
  }
};
</script>

<style scoped>
.profile-page .loader {
  height: calc(100dvh - 71px) !important;
}
</style>