<template>
  <div class="effects-section">
    <profile-section-wrapper title="بصمتي">
      <div ref="effectsSwiper" class="effects-swiper" v-if="effects?.length">
        <div class="swiper-wrapper">
          <div class="swiper-slide effect" v-for="effect, key in effects" :key="`effect-${key}`">
            <p>{{ effect.type }}</p>
            <p>{{ effect.courseName }}</p>
            <p>{{ effect.evaluation ?? 'غير مقييم' }}</p>
          </div>
        </div>
      </div>
      <p class="empty" v-else>لا يوجد بصمة لك</p>
    </profile-section-wrapper>
  </div>
</template>

<script>
import ProfileSectionWrapper from "../ProfileSectionWrapper.vue";
import Swiper, { Autoplay } from "swiper";
import "swiper/swiper-bundle.css";

export default {
  components: { ProfileSectionWrapper },
  props: {
    effects: {
      type: Array,
      default: () => [],
    },
  },
  mounted() {
    this.$nextTick(() => {
      new Swiper(this.$refs.effectsSwiper, {
        modules: [Autoplay],
        loop: false,
        speed: 1000,
        grabCursor: true,
        centeredSlides: false,
        // setWrapperSize: true,
        // noSwiping: false,
        // swiper-no-swiping
        autoplay: {
          delay: 3000,
          disableOnInteraction: false,
          pauseOnMouseEnter: true,
        },
        breakpoints: {
          200: {
            slidesPerView: 1,
            spaceBetween: 8,
          },
          400: {
            slidesPerView: 1.4,
            spaceBetween: 8,
          },
          600: {
            slidesPerView: 2.2,
            spaceBetween: 10,
          },
          800: {
            slidesPerView: 3.2,
            spaceBetween: 8,
          },
        },
      });
    });
  },
};
</script>

<style scoped>
.effects-swiper {
  overflow: hidden;
}

.effect {
  font-size: 15px;
  font-weight: 500;
  border: 2px solid #9DD8D4;
  border-radius: 10px;
  padding: 8px 20px;
}

.effect p:nth-child(1) {
  color: #9DD8D4;
  font-weight: 700;
  margin-bottom: 8px;
}

.effect p:nth-child(2) {
  color: #979797;
  font-weight: 600;
  font-size: 16px;
}

.effect p:nth-child(3) {
  color: #f4acbf;
}

.empty {
  color: #979797;
  font-weight: 600;
  font-size: 0.9rem;
}
</style>