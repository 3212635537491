<template>
    <img ref="barcode" />
</template>

<script>
import JsBarcode from "jsbarcode";

export default {
    props: {
        value: String
    },
    mounted() {
        this.generateBarcode();
    },
    watch: {
        value() {
            this.generateBarcode();
        }
    },
    methods: {
        generateBarcode() {
            JsBarcode(this.$refs.barcode, this.value, {
                format: "CODE128",
                lineColor: "#000",
                width: 2,
                height: 90,
                displayValue: true
            });
        }
    }
};
</script>