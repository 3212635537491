<template>
  <div class="statistics">
    <profile-header>
      <!-- <div class="rank" :style="{ background: rankColor }">
        <p>{{ data?.loyalty?.tier?.name }}</p>
      </div> -->
      <!-- <div class="avatar" :style="{ borderColor: rankColor }"> -->
      <div class="wrapper-content">
        <div class="avatar">
          <img src="../../../../../../public/assets/images/website/zadi/avatar.png" />
          <!-- <img :src="rankImage"> -->
        </div>
        <div class="content">
          <p>{{ data?.profile?.name }}</p>
          <span @click="$emit('on-change-step')">
            عرض المعلومات الشخصية
            <i class="fa fa-arrow-circle-o-left"></i>
          </span>
        </div>
      </div>
      <img src="../../../../../../public/assets/images/website/zadi/arrow.png" class="back-button"
        @click="$router.push({ name: 'ZadiHome' })" />
    </profile-header>
    <div class="wrapper">
      <statistics-section :statistics="data" />
      <contributions-section :contributions="data?.profile?.motivationParticipation" />
      <effects-section :effects="data?.profile?.bassmatyProjectInfo" />
      <rewards-section :rewards="data?.profile?.motivationParticipation" />
      <trainings-section :lectures="data?.profile?.attendedLectures" :courses="data?.profile?.attendedCourses" />
    </div>
  </div>
</template>

<script>
import ProfileSectionWrapper from "../ProfileSectionWrapper.vue";
import ContributionsSection from "./ContributionsSection.vue";
import EffectsSection from "./EffectsSection.vue";
import RewardsSection from "./RewardsSection.vue";
import TrainingsSection from "./TrainingsSection.vue";
import StatisticsSection from "./StatisticsSection.vue";
import ProfileHeader from "../ProfileHeader";

export default {
  components: { ProfileHeader, ProfileSectionWrapper, ContributionsSection, EffectsSection, RewardsSection, TrainingsSection, StatisticsSection },
  props: {
    data: Object,
  },
  computed: {
    // rankImage() {
    //   const type = 1;
    //   switch (type) {
    //     case 1:
    //       return require("../../../../../../public/assets/images/website/zadi/first-icon.png");
    //     case 2:
    //       return require("../../../../../../public/assets/images/website/zadi/second-icon.png");
    //     case 3:
    //       return require("../../../../../../public/assets/images/website/zadi/third-icon.png");
    //     default:
    //       return {};
    //   }
    // },
    // rankColor() {
    //   const type = 1;
    //   switch (type) {
    //     case 1:
    //       return '#FAD95C';
    //     case 2:
    //       return '#E0E0E0';
    //     case 3:
    //       return '#DCABAB';
    //   }
    // }
  },
};
</script>

<style scoped>
.wrapper {
  max-width: 768px;
  margin: 0 auto;
  padding: 0px 40px 80px 40px;
}

.wrapper>div:not(:last-child) {
  margin-bottom: 40px;
}

.statistics .profile-header {
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  justify-content: space-between;
  /* column-gap: 15px; */
}

/* .statistics .profile-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 15px;
} */

.statistics .profile-header .back-button {
  width: 18px;
  cursor: pointer;
  align-self: flex-start;
  margin-top: 43px;
}

.statistics .profile-header .wrapper-content {
  margin: 0 auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  row-gap: 15px;
}

/* .statistics .profile-header .rank {
  padding: 6px 14px;
  border-radius: 24px;
  color: white;
  font-size: 13px;
  font-weight: 600;
  box-shadow: 3px 9px 8px rgba(106, 106, 106, 16%);
  align-self: flex-end;
} */

.statistics .profile-header .wrapper-content .avatar {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  /* border: 4px solid; */
  position: relative;
  box-shadow: 3px 9px 8px rgba(106, 106, 106, 16%);
}

.statistics .profile-header .wrapper-content .avatar img:first-child {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* .statistics .profile-header .avatar img:last-child {
  position: absolute;
  bottom: -9px;
  right: 50%;
  transform: translateX(50%);
} */

.statistics .profile-header .wrapper-content .content {
  text-align: center;
}

.statistics .profile-header .wrapper-content .content p {
  color: #9DD8D4;
  font-size: 18px;
  font-weight: 700;
}

.statistics .profile-header .wrapper-content .content span {
  color: #C1C1C1;
  font-size: 15px;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
}

.statistics .profile-header .wrapper-content .content span:hover i {
  transform: translateX(-4px);
}

.statistics .profile-header .wrapper-content .content span i {
  transition: all 0.2s ease-in-out;
  font-size: 25px;
}

@media (max-width: 560px) {
  .wrapper {
    padding: 0px 20px 80px 20px;
  }
}
</style>
