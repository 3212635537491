<template>
  <div class="profile-section">
    <p class="title">{{ title }}</p>
    <div class="wrapper">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: () => null,
    },
  },
};
</script>

<style scoped>
.title {
  color: #C1C1C1;
  font-size: 20px;
  font-weight: 600;
}

.wrapper {
  padding: 14px 0px 0px 0px;
}
</style>