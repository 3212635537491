<template>
  <div class="rewards-section">
    <profile-section-wrapper title="المكافآت">
      <div class="rewards-wrapper" v-if="getRewards?.length">
        <span class="reward" v-for="reward, key in getRewards" :key="`reward-${key}`">
          {{ reward.title }}
        </span>
      </div>
      <p class="empty" v-else>لا يوجد مكافآت</p>
    </profile-section-wrapper>
  </div>
</template>

<script>
import ProfileSectionWrapper from "../ProfileSectionWrapper.vue";

export default {
  components: { ProfileSectionWrapper },
  props: {
    rewards: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    getRewards() {
      return this.rewards.filter(reward => reward.type == 'حوافز');
    },
  },
};
</script>

<style scoped>
.rewards-wrapper {
  border: 2px solid #9DD8D4;
  border-radius: 10px;
  display: inline-block;
  width: 100%;
  box-shadow: 3px 9px 8px rgba(106, 106, 106, 16%);
}

.reward {
  color: #979797;
  display: block;
  font-size: 15px;
  font-weight: 500;
  padding: 10px 20px;
  font-weight: 600;
  font-size: 16px;
}

.reward:not(:last-child) {
  border-bottom: 2px solid #E5E5E5;
}

.empty {
  color: #979797;
  font-weight: 600;
  font-size: 0.9rem;
}
</style>