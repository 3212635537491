<template>
  <div class="contributions-section">
    <profile-section-wrapper title="مشاركاتي التطوعية">
      <div class="contributions-wrapper" v-if="getContributions?.length">
        <span class="contribution" v-for="contribution, key in getContributions" :key="`contribution-${key}`">
          {{ contribution.title }}
        </span>
      </div>
      <p class="empty" v-else>لا يوجد مشاركات تطوعية</p>
    </profile-section-wrapper>
  </div>
</template>

<script>
import ProfileSectionWrapper from "../ProfileSectionWrapper.vue";

export default {
  components: { ProfileSectionWrapper },
  props: {
    contributions: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    getContributions() {
      return this.contributions.filter(contribution => contribution.type == 'مشاركات');
    },
  },
};
</script>

<style scoped>
.contributions-wrapper {
  display: flex;
  align-items: center;
  gap: 8px;
  flex-wrap: wrap;
}

.contribution {
  color: #979797;
  font-size: 16px;
  font-weight: 600;
  border: 2px solid #9DD8D4;
  border-radius: 10px;
  padding: 8px 12px;
  box-shadow: 3px 9px 8px rgba(106, 106, 106, 16%);
}

.empty {
  color: #979797;
  font-weight: 600;
  font-size: 0.9rem;
}
</style>