var render = function () {
  var _vm$lectures, _vm$courses;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "trainings-section"
  }, [_c('profile-section-wrapper', {
    attrs: {
      "title": "تمكين وتدريب"
    }
  }, [(_vm$lectures = _vm.lectures) !== null && _vm$lectures !== void 0 && _vm$lectures.length || (_vm$courses = _vm.courses) !== null && _vm$courses !== void 0 && _vm$courses.length ? _c('div', {
    staticClass: "trainings-wrapper"
  }, [_vm._l(_vm.lectures, function (lecture, key) {
    return _c('div', {
      key: ("lecture-" + key),
      staticClass: "training"
    }, [_c('p', [_vm._v(_vm._s(lecture.lectureName))]), _c('span', {
      style: _vm.typeStyle('محاضرة')
    }, [_vm._v("محاضرة")])]);
  }), _vm._l(_vm.courses, function (course, key) {
    return _c('div', {
      key: ("course-" + key),
      staticClass: "training"
    }, [_c('p', [_vm._v(_vm._s(course.courseName))]), _c('span', {
      style: _vm.typeStyle('تدريب')
    }, [_vm._v("تدريب")])]);
  })], 2) : _c('p', {
    staticClass: "empty"
  }, [_vm._v("لا يوجد محاضرات او تدريبات")])])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }