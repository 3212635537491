var render = function () {
  var _vm$getContributions;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "contributions-section"
  }, [_c('profile-section-wrapper', {
    attrs: {
      "title": "مشاركاتي التطوعية"
    }
  }, [(_vm$getContributions = _vm.getContributions) !== null && _vm$getContributions !== void 0 && _vm$getContributions.length ? _c('div', {
    staticClass: "contributions-wrapper"
  }, _vm._l(_vm.getContributions, function (contribution, key) {
    return _c('span', {
      key: ("contribution-" + key),
      staticClass: "contribution"
    }, [_vm._v(" " + _vm._s(contribution.title) + " ")]);
  }), 0) : _c('p', {
    staticClass: "empty"
  }, [_vm._v("لا يوجد مشاركات تطوعية")])])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }