<template>
  <div class="trainings-section">
    <profile-section-wrapper title="تمكين وتدريب">
      <div class="trainings-wrapper" v-if="lectures?.length || courses?.length">
        <div class="training" v-for="lecture, key in lectures" :key="`lecture-${key}`">
          <p>{{ lecture.lectureName }}</p>
          <span :style="typeStyle('محاضرة')">محاضرة</span>
        </div>
        <div class="training" v-for="course, key in courses" :key="`course-${key}`">
          <p>{{ course.courseName }}</p>
          <span :style="typeStyle('تدريب')">تدريب</span>
        </div>
      </div>
      <p class="empty" v-else>لا يوجد محاضرات او تدريبات</p>
    </profile-section-wrapper>
  </div>
</template>

<script>
import ProfileSectionWrapper from "../ProfileSectionWrapper.vue";

export default {
  components: { ProfileSectionWrapper },
  props: {
    lectures: {
      type: Array,
      default: () => [],
    },
    courses: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    typeStyle(type) {
      return {
        background: type === 'تدريب' ? '#9DD8D4' : '#f4acbf',
      };
    },
  },
};
</script>

<style scoped>
.trainings-wrapper {
  border: 2px solid #9DD8D4;
  border-radius: 10px;
  display: inline-block;
  width: 100%;
  box-shadow: 3px 9px 8px rgba(106, 106, 106, 16%);
}

.training {
  color: #979797;
  display: block;
  font-weight: 500;
  padding: 10px 20px;
  font-weight: 600;
  font-size: 16px;
  display: flex;
  align-items: center;
  gap: 8px;
  flex-wrap: wrap;
}

.training:not(:last-child) {
  border-bottom: 2px solid #E5E5E5;
}

.training span {
  background: #9DD8D4;
  color: white;
  border-radius: 12px;
  padding: 2px 10px;
  font-size: 14px;
  font-weight: 600;
}

.empty {
  color: #979797;
  font-weight: 600;
  font-size: 0.9rem;
}
</style>