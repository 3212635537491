<template>
  <div class="personal-info">
    <profile-header>
      <div class="content">
        <p>المعلومات الشخصية</p>
        <img src="../../../../../../public/assets/images/website/zadi/arrow.png" @click="$emit('on-change-step')" />
      </div>
    </profile-header>
    <div class="wrapper row">
      <div class="col-sm-6 col-12">
        <div class="info">
          <p>رقم البطاقة</p>
          <p>{{ data?.cardNumber || '--' }}</p>
        </div>
      </div>
      <div class="col-sm-6 col-12">
        <div class="info">
          <p>الاسم الثلاثي</p>
          <p>{{ data?.name || '--' }}</p>
        </div>
      </div>
      <div class="col-sm-6 col-12">
        <div class="info">
          <p>أقرب هاتف</p>
          <p>{{ data?.volunteerInfo?.nearestPhoneNumber || '--' }}</p>
        </div>
      </div>
      <div class="col-sm-6 col-12">
        <div class="info">
          <p>رقم البصمة</p>
          <p>{{ data?.volunteerInfo?.fingerprint || '--' }}</p>
        </div>
      </div>
      <div class="col-sm-6 col-12">
        <div class="info">
          <p>حالة البطاقة</p>
          <p>{{ data?.volunteerInfo?.cardStatus || '--' }}</p>
        </div>
      </div>
      <div class="col-sm-6 col-12">
        <div class="info">
          <p>المواليد</p>
          <p>{{ data?.volunteerInfo?.birthdate || '--' }}</p>
        </div>
      </div>
      <div class="col-sm-6 col-12">
        <div class="info">
          <p>الجنس</p>
          <p>{{ data?.volunteerInfo?.gender || '--' }}</p>
        </div>
      </div>
      <div class="col-sm-6 col-12">
        <div class="info">
          <p>اسم المجموعة</p>
          <p>{{ data?.volunteerInfo?.groupName || '--' }}</p>
        </div>
      </div>
      <div class="col-sm-6 col-12">
        <div class="info">
          <p>تاريخ طلب التطوع</p>
          <p>{{ data?.volunteerInfo?.volunteerDate || '--' }}</p>
        </div>
      </div>
      <div class="col-sm-6 col-12">
        <div class="info">
          <p>السنة الدراسية</p>
          <p>{{ data?.volunteerInfo?.studyYear || '--' }}</p>
        </div>
      </div>
      <div class="col-sm-6 col-12">
        <div class="info">
          <p>الفرع الجامعي</p>
          <p>{{ data?.volunteerInfo?.collage || '--' }}</p>
        </div>
      </div>
      <div class="col-12">
        <barcode-generator :value="data?.volunteerInfo?.barcode" />
      </div>
    </div>
  </div>
</template>

<script>
import BarcodeGenerator from "./BarcodeGenerator.vue";
import ProfileHeader from "../ProfileHeader";

export default {
  components: { BarcodeGenerator, ProfileHeader },
  props: {
    data: Object,
  },
};
</script>

<style scoped>
.profile-header {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.profile-header .content {
  margin-top: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  flex-direction: row-reverse;
  column-gap: 15px;
}

.profile-header .content p {
  margin: 0 auto;
  font-size: 18px;
  font-weight: 700;
}

.profile-header .content img {
  width: 18px;
  cursor: pointer;
}

.wrapper {
  margin-left: -4px;
  margin-right: -4px;

  max-width: 768px;
  margin: 0 auto;
  margin-top: -70px;
  padding: 0px 40px 80px 40px;
}

.col-12,
.col-sm-6 {
  padding: 8px 4px;
}

.info p:nth-child(1) {
  color: #C1C1C1;
  font-size: 16px;
  font-weight: 600;
  padding-inline-start: 8px;
  margin-bottom: 4px;
}

.info p:nth-child(2) {
  border: 2px solid #D5D5D5;
  padding: 8px 12px;
  border-radius: 10px;
  font-size: 16px;
  font-weight: 500;
  color: #979797;
}

.wrapper img {
  max-width: 280px;
  width: 100%;
  margin: 0 auto;
}

@media (max-width: 560px) {
  .wrapper {
    padding: 0px 20px 80px 20px;
  }
}
</style>