import { httpClient } from "../../shared/services";

const { VUE_APP_VOLUNTEER_API_BASE_URL, VUE_APP_LOYALTY_API_BASE_URL } = process.env
const volunteerApiBaseUrl = VUE_APP_VOLUNTEER_API_BASE_URL
const loyaltyApiBaseUrl = VUE_APP_LOYALTY_API_BASE_URL

export default {
  getUserProfile() {
    const url = `${volunteerApiBaseUrl}v1/auth/my-profile`
    return httpClient.get(url)
  },
  getUserPoints(id) {
    const url = `${loyaltyApiBaseUrl}v1/apps/clients/${id}?join=tier&join=coupons`
    return httpClient.get(url, {
      skipGlobalErrorHandler: true,
    })
  },
}
