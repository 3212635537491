var render = function () {
  var _vm$getRewards;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "rewards-section"
  }, [_c('profile-section-wrapper', {
    attrs: {
      "title": "المكافآت"
    }
  }, [(_vm$getRewards = _vm.getRewards) !== null && _vm$getRewards !== void 0 && _vm$getRewards.length ? _c('div', {
    staticClass: "rewards-wrapper"
  }, _vm._l(_vm.getRewards, function (reward, key) {
    return _c('span', {
      key: ("reward-" + key),
      staticClass: "reward"
    }, [_vm._v(" " + _vm._s(reward.title) + " ")]);
  }), 0) : _c('p', {
    staticClass: "empty"
  }, [_vm._v("لا يوجد مكافآت")])])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }