var render = function () {
  var _vm$data, _vm$data$profile, _vm$data2, _vm$data2$profile, _vm$data3, _vm$data3$profile, _vm$data4, _vm$data4$profile, _vm$data5, _vm$data5$profile, _vm$data6, _vm$data6$profile;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "statistics"
  }, [_c('profile-header', [_c('div', {
    staticClass: "wrapper-content"
  }, [_c('div', {
    staticClass: "avatar"
  }, [_c('img', {
    attrs: {
      "src": require("../../../../../../public/assets/images/website/zadi/avatar.png")
    }
  })]), _c('div', {
    staticClass: "content"
  }, [_c('p', [_vm._v(_vm._s((_vm$data = _vm.data) === null || _vm$data === void 0 ? void 0 : (_vm$data$profile = _vm$data.profile) === null || _vm$data$profile === void 0 ? void 0 : _vm$data$profile.name))]), _c('span', {
    on: {
      "click": function ($event) {
        return _vm.$emit('on-change-step');
      }
    }
  }, [_vm._v(" عرض المعلومات الشخصية "), _c('i', {
    staticClass: "fa fa-arrow-circle-o-left"
  })])])]), _c('img', {
    staticClass: "back-button",
    attrs: {
      "src": require("../../../../../../public/assets/images/website/zadi/arrow.png")
    },
    on: {
      "click": function ($event) {
        return _vm.$router.push({
          name: 'ZadiHome'
        });
      }
    }
  })]), _c('div', {
    staticClass: "wrapper"
  }, [_c('statistics-section', {
    attrs: {
      "statistics": _vm.data
    }
  }), _c('contributions-section', {
    attrs: {
      "contributions": (_vm$data2 = _vm.data) === null || _vm$data2 === void 0 ? void 0 : (_vm$data2$profile = _vm$data2.profile) === null || _vm$data2$profile === void 0 ? void 0 : _vm$data2$profile.motivationParticipation
    }
  }), _c('effects-section', {
    attrs: {
      "effects": (_vm$data3 = _vm.data) === null || _vm$data3 === void 0 ? void 0 : (_vm$data3$profile = _vm$data3.profile) === null || _vm$data3$profile === void 0 ? void 0 : _vm$data3$profile.bassmatyProjectInfo
    }
  }), _c('rewards-section', {
    attrs: {
      "rewards": (_vm$data4 = _vm.data) === null || _vm$data4 === void 0 ? void 0 : (_vm$data4$profile = _vm$data4.profile) === null || _vm$data4$profile === void 0 ? void 0 : _vm$data4$profile.motivationParticipation
    }
  }), _c('trainings-section', {
    attrs: {
      "lectures": (_vm$data5 = _vm.data) === null || _vm$data5 === void 0 ? void 0 : (_vm$data5$profile = _vm$data5.profile) === null || _vm$data5$profile === void 0 ? void 0 : _vm$data5$profile.attendedLectures,
      "courses": (_vm$data6 = _vm.data) === null || _vm$data6 === void 0 ? void 0 : (_vm$data6$profile = _vm$data6.profile) === null || _vm$data6$profile === void 0 ? void 0 : _vm$data6$profile.attendedCourses
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }